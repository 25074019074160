<template>
  <v-dialog
    content-class="ticket__quantity__Wrap"
    v-model="dialog"
    max-width="600"
  >
    <v-btn
      :ripple="false"
      icon
      class="ticket-quanitiy-close float-right mr-4 mt-4"
      @click.stop="dialog = false"
    >
      <v-icon color="rgba(31, 31, 31, 0.9)">mdi-close</v-icon>
    </v-btn>
    <v-card class="dailog-wrap ma-0">
      <div class="d-flex align-center warn-img">
        <img src="@/assets/images/warning.svg" alt="" />
      </div>
      <v-card-title class="justify-center wait-header">
        Wait a minute!
      </v-card-title>
      <v-card-text class="dailog-info justify-center">
        You have chosen only <span color="#070B32">one ticket</span> – if you
        wish to choose more than one ticket, then please add 2x tickets in the
        ticket quantity dropdown.
      </v-card-text>
      <v-card-actions class="btn-confirm">
        <div>
          <v-btn
            :ripple="false"
            color="green darken-1"
            text
            outlined
            @click.stop="
              dialog = false;
              $emit('selected');
            "
            class="seat-one"
          >
            Yes, I only want one ticket
            <img class="ml-1" src="@/assets/images/right-arrow.svg" alt="" />
          </v-btn>
          <v-btn
            :ripple="false"
            color="green darken-1"
            text
            outlined
            @click.stop="dialog = false"
            class="seat-two"
          >
            No, I need more than one ticket
            <img class="ml-1" src="@/assets/images/right-arrow.svg" alt="" />
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>

<template>
  <div class="container--fluid pa-0">
    <the-header />
    <tickets-banner-card />
    <scrollactive class="h-menu" :offset="130">
      <a href="#buy-tickets" class="scrollactive-item">Buy Tickets</a>
      <a href="#stadium-info" class="scrollactive-item">Stadium Info</a>
      <a href="#disclaimer" class="scrollactive-item">Disclaimer</a>
      <a href="#other-fixtures" class="scrollactive-item">Other Fixtures</a>
    </scrollactive>
    <!-- <div class="h-menu">
      <ul>
        <li><a class="active" href="#buy-tickets">Buy Tickets</a></li>
        <li><a href="#stadium-info">Stadium Info</a></li>
        <li><a href="#disclaimer">Disclaimer</a></li>
        <li><a href="#other-fixtures">Other Fixtures</a></li>
      </ul>
    </div> -->
    <v-divider class="line"></v-divider>
    <template v-if="fixtureDetails">
      <div class="container--fluid pt-0" id="buy-tickets">
        <the-buy-tickets />
      </div>
      <div class="container--fluid" id="stadium-info">
        <the-venue />
      </div>
      <div class="container--fluid" id="disclaimer">
        <the-features />
        <the-must-read
          :info="`${fixtureDetails.team1_name} vs ${fixtureDetails.team2_name}`"
        />
      </div>
      <div class="container" id="other-fixtures">
        <the-upcoming-fixtures />
      </div>
    </template>
    <template v-else>
      <v-row class="pa-10">
        <v-col cols="12" md="6">
          <v-skeleton-loader
            v-bind="{ boilerplate: false }"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-for="i in 10"
            :key="i"
            v-bind="{ boilerplate: false }"
            type="article, actions"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader
            v-for="i in 10"
            :key="i"
            v-bind="{ boilerplate: false }"
            type="list-item-avatar-three-line, actions"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-bind="{ boilerplate: false }"
            type="table-heading, list-item-two-line, image, table-tfoot"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </template>
    <div
      v-if="!fixtureDetails && !overlay"
      class="container text-center pa-16"
      id="other-fixtures"
    >
      <h1 class="ma-10">No information found for this fixture</h1>
      <v-btn :ripple="false" to="/" outlined class="btn-sell-ticket"
        >Go to Home</v-btn
      >
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheHeader from "../components/TheHeader.vue";
import TicketsBannerCard from "../components/TicketsBannerCard.vue";
import TheBuyTickets from "../components/TheBuyTickets.vue";
import TheMustRead from "../components/TheMustRead.vue";
import TheUpcomingFixtures from "../components/TheUpcomingFixtures.vue";
import TheVenue from "../components/TheVenue.vue";
import TheFeatures from "../components/TheFeatures.vue";
import TheFooter from "../components/TheFooter.vue";
export default {
  name: "Fixtures",
  components: {
    TheHeader,
    TicketsBannerCard,
    TheBuyTickets,
    TheVenue,
    TheFeatures,
    TheMustRead,
    TheUpcomingFixtures,
    TheFooter,
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.$store.dispatch("fixtures/GET_FIXTURE_DETAILS", {
          tournamnet_slug: val.tournamentSlug,
          fixture_slug: val.fixtureSlug,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    (function (d) {
      var s = d.createElement("script");
      s.async = true;
      s.src = "https://cdn-eu.seatsio.net/chart.js";
      (d.head || d.body).appendChild(s);
    })(document);
  },
  computed: {
    overlay() {
      return this.$store.state.app.wait;
    },
    fixtureDetails() {
      return this.$store.state.fixtures.fixtureDetails;
    },
  },
};
</script>
<style></style>

<template>
  <div class="d-flex fixtures-panel container">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" md="4" class="upcoming-fixtures">
        <h3 class="fixture-header">Upcoming Fixtures</h3>

        <div
          @click="callForUpcomingFixtures('team')"
          class="fixture cursor"
          :class="'team_id' in payload ? 'active' : ''"
        >
          <h3 text class="header">{{ fixtureDetails.team1_name }}</h3>
          <p class="caption">
            Upcoming fixtures for {{ fixtureDetails.team1_name }}
          </p>
        </div>

        <div
          @click="callForUpcomingFixtures('stadium')"
          class="fixture cursor"
          :class="'stadium_id' in payload ? 'active' : ''"
        >
          <h3 text class="header">{{ fixtureDetails.stadium_name }}</h3>
          <p class="caption">
            Upcoming fixtures at {{ fixtureDetails.stadium_name }}
          </p>
        </div>

        <div
          @click="callForUpcomingFixtures('city')"
          class="fixture cursor"
          :class="'city_id' in payload ? 'active' : ''"
        >
          <h3 text class="header">Fixtures Nearby</h3>
          <p class="caption">Upcoming fixtures at nearly stadiums</p>
        </div>
      </v-col>
      <v-col
        v-if="upcomingFixtures.length"
        cols="12"
        md="8"
        class="fixtures-list"
      >
        <div class="upcoming-events">
          <event-card
            v-for="item in upcomingFixtures"
            :key="item.id"
            :event-info="item"
            class="mb-2"
          />
        </div>
        <div class="view-all">
          <v-btn
            :ripple="false"
            class="px-0"
            :to="`/teams/${this.fixtureDetails.team1_slug}`"
            text
          >
            View all {{ fixtureDetails.team1_name }} fixtures
            <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg"
          /></v-btn>
          <v-divider class="line"></v-divider>
        </div>
      </v-col>
      <v-card
        v-if="upcomingFixtures.length === 0"
        class="event-card"
        elevation="10"
      >
        <v-col cols="12" sm="12" class="pa-15 event-info">
          Sorry there are no related fixtures
        </v-col>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import EventCard from "./EventMolecules/EventCard.vue";
export default {
  name: "TheUpcomingFixtures",
  data: () => ({
    payload: {},
  }),
  components: { EventCard },
  computed: {
    fixtureDetails() {
      return this.$store.state.fixtures.fixtureDetails;
    },
    upcomingFixtures() {
      return this.$store.state.fixtures.upcomingByType;
    },
  },
  mounted() {
    this.callForUpcomingFixtures("team");
  },
  methods: {
    async callForUpcomingFixtures(type) {
      this.payload = {};
      switch (type) {
        case "team":
          this.payload.team_id = this.fixtureDetails.team1_id;
          break;
        case "city":
          this.payload.city_id = this.fixtureDetails.stadium_city_id;
          break;
        case "stadium":
          this.payload.stadium_id = this.fixtureDetails.stadium_id;
          break;
        default:
          break;
      }
      await this.$store.dispatch("fixtures/GET_UPCOMING_BY_TYPE", this.payload);
    },
  },
};
</script>

<style></style>

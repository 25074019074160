<template>
  <div v-if="fixtureDetails" class="venue-details">
    <v-container>
      <v-row class="ma-0">
        <v-col cols="12" md="6" class="address">
          <venue-card :info="fixtureDetails"></venue-card>
        </v-col>
        <v-col id="nearby" cols="12" md="6" class="nearby-panel">
          <near-by-box-info :info="fixtureDetails" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VenueCard from "./VenueMolecules/VenueCard.vue";
import NearByBoxInfo from "./VenueMolecules/NearByBoxInfo.vue";
export default {
  components: { VenueCard, NearByBoxInfo },
  data() {
    return {
      places: [
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
      ],
    };
  },
  computed: {
    fixtureDetails() {
      return this.$store.state.fixtures.fixtureDetails;
    },
  },
};
</script>

<style></style>

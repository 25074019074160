<template>
  <div class="bg-grey-light">
    <v-container>
      <v-row>
        <v-col cols="12">
          <p class="read-title">
            Information you
            <span class="font-weight-bold"> must read and know </span> before
            buying {{ info }} Tickets:
          </p>
          <div v-html="disclaimer"></div>
        </v-col>
      </v-row>
    </v-container>
    <!-- <div class="container must-read">
      <ul>
        <li class="must-points ml-2">
          Event date and time are subject to change - these changes are not
          connected to Football Tickets Live but to the official organizer - you
          are advised to check occasionally the correct date and time and make
          your personal arrangements for attending the match on the new date and
          time. We are sorry but no refunds can be made for rescheduled matches
          - tickets will be valid to the new date and time
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "TheMustRead",
  data: () => ({
    disclaimer: null,
  }),
  props: {
    info: {
      Type: String,
      default() {
        return "";
      },
    },
  },
  mounted() {
    this.$store
      .dispatch("static/STATIC_DATA_BY_KEY", {
        key: "fixture_disclaimer",
      })
      .then((res) => {
        this.disclaimer = res.data.value;
      });
  },
};
</script>

<style></style>

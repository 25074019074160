<template>
  <div class="book-tickets-panel">
    <div class="seats-wrap container">
      <v-row>
        <v-col cols="12" sm="12" md="6" class="seatingview">
          <div class="seating__wrap">
            <div id="chart-1234"></div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="availabletickets">
          <h3 class="ticket-status">Available Tickets</h3>
          <!-- <div class="d-flex best-price justify-space-between">
            <v-btn :ripple="false" text outlined class="single">
              Best Price Single Seat
              <img
                class="ml-2 mt-1"
                src="@/assets/images/right-arrow.svg"
                alt=""
              />
            </v-btn>
            <v-btn :ripple="false" text outlined class="double"
              >Best Price Double Seats<img
                class="ml-2 mt-1"
                src="@/assets/images/right-arrow.svg"
                alt=""
              />
            </v-btn>
          </div> -->
          <v-simple-table class="seat-ticket-table">
            <template v-slot:default>
              <thead class="table-header">
                <tr>
                  <th class="table-header1">
                    Category / Stand
                    <!-- <v-tooltip
                      content-class="doc-tooltip"
                      color="#fff"
                      text-color="#141715"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          size="17"
                          class="ml-1 mt-n1 icon-anti"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span class="content"
                        >Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry</span
                      >
                    </v-tooltip> -->
                  </th>
                  <th
                    class="table-header2 text-center"
                    v-if="!$vuetify.breakpoint.xsOnly"
                  >
                    Seats
                    <!-- <v-tooltip
                      content-class="doc-tooltip"
                      color="#fff"
                      text-color="#141715"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          size="17"
                          class="ml-1 mt-n1 icon-anti"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span class="content"
                        >Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry</span
                      >
                    </v-tooltip> -->
                  </th>
                  <th class="table-header3">Qty</th>
                  <th class="table-header3">Price</th>
                </tr>
              </thead>
              <tbody v-if="fixtureDetails" class="table-info">
                <tr
                  v-for="(data, index) in fixtureDetails.fixture_category_price"
                  :key="index"
                  :class="
                    data.total_seats === data.booked_seats
                      ? 'soldout'
                      : data.highlighted
                      ? 'primary'
                      : ''
                  "
                  @mouseover="doHiglightCats([data.stadium_category.label])"
                  @mouseout="doHiglightCats([])"
                >
                  <td v-if="data.stadium_category" class="name bt-title">
                    {{ data.stadium_category.label | capitalize }}
                    <p class="caption" v-if="!$vuetify.breakpoint.xsOnly">
                      {{
                        data.stadium_category.description
                          ? data.stadium_category.description
                          : `Ticket, stand or category description`
                      }}
                    </p>
                  </td>
                  <td v-if="!data.stadium_category" class="name">
                    Seat
                    <p class="caption">Ticket, stand or category description</p>
                  </td>
                  <td v-if="!$vuetify.breakpoint.xsOnly" class="text-center">
                    <span
                      class="seat"
                      v-for="item in data.seat_type"
                      :key="item"
                    >
                      <img :src="require('@/assets/images/Chairs.png')"
                    /></span>
                  </td>
                  <td>
                    <v-select
                      v-model="data.quantity"
                      :items="numbers"
                      solo
                      flat
                      outlined
                      class="quantity"
                      hide-details="auto"
                    >
                      <template slot="append">
                        <img src="@/assets/images/Arrow.png" />
                      </template>
                    </v-select>
                  </td>
                  <td>
                    <div class="d-flex align-center justify-space-between">
                      <span class="price">
                        {{ data.price | currency("£") }}
                      </span>
                      <v-btn
                        :ripple="false"
                        v-if="data.total_seats !== data.booked_seats"
                        @click.stop="validateQuantity(data)"
                        class="btn-buy"
                        elevation="0"
                      >
                        Buy
                        <img
                          class="ml-2 mt-1"
                          src="@/assets/images/right-arrow.svg"
                          alt="Arrow"
                        />
                      </v-btn>
                      <v-btn
                        text
                        :ripple="false"
                        class="sold"
                        v-if="data.total_seats === data.booked_seats"
                      >
                        Sold Out
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else v-for="data in 4" :key="data" class="table-info">
                <v-skeleton-loader
                  v-bind="{
                    class: 'mb-6',
                    boilerplate: false,
                    elevation: 0,
                  }"
                  type="list-item-two-line"
                ></v-skeleton-loader>
              </tbody>
            </template>
          </v-simple-table>
          <div class="d-flex sell-ticket">
            <div class="d-flex tag-sec">
              <img
                class="tag-icon"
                src="@/assets/images/Vector.png"
                alt="Vector"
              />
              <div class="tag-name">Have a ticket to sell?</div>
            </div>
            <div>
              <v-btn
                to="/sell-your-tickets"
                :ripple="false"
                outlined
                class="btn-sell-ticket"
              >
                Sell Your Tickets
                <img class="ml-2 mt-1" src="@/assets/images/blue-arrow.svg"
              /></v-btn>
            </div>
          </div>
          <div class="d-flex imp-points">
            <img
              class="points-img"
              src="@/assets/images/points-img.svg"
              alt="points-img"
            />
            <div>
              <h3 class="header">What you need to know</h3>
              <ul class="points-list">
                <li class="points ml-2">
                  Event times are subject to change - so please check with the
                  venue for start times and/or age restrictions.
                </li>
                <li class="points ml-2">
                  Your total includes local sales tax and a service fee.
                </li>
                <li class="points ml-2">Sales are final.</li>
                <li class="points ml-2">No cancellations.</li>
              </ul>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <ticket-quantity-modal
      v-model="showTicketQuantityModal"
      @selected="createGuestOrder"
    />
  </div>
</template>
<script>
import TicketQuantityModal from "@/components/TicketQuantityModal.vue";
export default {
  name: "TheBuyTickets",
  components: { TicketQuantityModal },
  data: () => ({
    displayURL: process.env.VUE_APP_ID,
    cInt: null,
    navigationTab: null,
    showTicketQuantityModal: false,
    selected: null,
    availableCategories: [],
    numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  }),
  mounted() {
    this.makeAnInstance();
  },
  computed: {
    fixtureDetails() {
      return this.$store.state.fixtures.fixtureDetails;
    },
  },
  methods: {
    makeAnInstance() {
      let vm = this;
      let highlighted = [];
      // eslint-disable-next-line no-undef
      this.cInt = new seatsio.SeatingChart({
        divId: "chart-1234",
        workspaceKey: this.displayURL,
        event: this.fixtureDetails.seat_id_display_url,
        filteredCategories: highlighted,
        numberOfPlacesToSelect: 1,
        objectTooltip: {
          showActionHint: false,
          showAvailability: false,
          showCategory: true,
          showLabel: false,
          showPricing: false,
          showUnavailableNotice: false,
          stylizedLabel: false,
        },
        categoryFilter: {
          enabled: true,
          multiSelect: false,
          zoomOnSelect: true,
        },
        canGASelectionBeIncreased: function (gaArea, defaultValue) {
          console.log(gaArea, defaultValue);
          return;
        },
        onObjectMouseOver: function (object) {
          highlighted = [object.category.label];
          vm.doHiglightCats([object.category.label]);
        },
        onObjectMouseOut: function (object) {
          console.log(object);
          vm.doHiglightCats([]);
        },
        onFilteredCategoriesChanged: function (object) {
          console.log(object);
          if (object.length) vm.doHiglightCats([object[0].label]);
          else vm.doHiglightCats([]);
        },
      }).render();
    },
    doHiglightCats(data) {
      this.cInt.changeConfig({
        availableCategories: data,
      });
      this.highlightTickets(data[0]);
    },
    highlightTickets(label) {
      this.$store.dispatch("fixtures/HIGHLIGHTED", { label });
    },
    validateQuantity(data) {
      this.selected = data;
      if (this.selected.quantity < 2) this.showTicketQuantityModal = true;
      else this.createGuestOrder();
    },
    async createGuestOrder() {
      const payload = {
        fixture_id: this.selected.fixture_id,
        fixture_category_price_id: this.selected.id,
        quantity: this.selected.quantity ? this.selected.quantity : 1,
      };
      // detarmmine the user typr loggedin or guest and call their respective endpoints
      if (this.$store.getters.token && this.$store.state.auth.loggedInUser) {
        await this.$store
          .dispatch("orders/CREATE_ORDER_FOR_LOGGEDIN_USER", payload)
          .then((response) => {
            this.$router.push(`/checkout/${response.data.slug}`);
          });
      } else {
        await this.$store
          .dispatch("orders/CREATE_GUEST_ORDER", payload)
          .then((response) => {
            this.$router.push(`/checkout/${response.data.slug}`);
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex nearby">
      <img class="maplogo mr-4" src="@/assets/images/locationpin.png" alt="" />
      <h2 class="nearby-info">What’s nearby</h2>
    </div>
    <div class="nearby-table">
      <v-tabs v-model="navigationTab" left class="nearby-menu">
        <v-tab
          v-for="(place, index) in info.stadium.stadium_nearby"
          :key="index"
          class="name"
          :href="`#${place.id}tab`"
        >
          {{ place.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="navigationTab">
        <v-tab-item
          v-for="(place, index) in info.stadium.stadium_nearby"
          :key="index"
          :value="`${place.id}tab`"
          :eager="true"
        >
          <!-- <div class="place-list d-flex align-center">
            <span v-if="place.description" class="place-name">
              {{ place.description }}
            </span>
            <span v-if="place.image" class="distance"> {{ place.image }} </span>
            <span v-if="place.url" class="distance"> {{ place.url }} </span>
          </div> -->
          <div class="place-list d-flex align-center">
            <span v-if="place.description" class="place-name">
              {{ place.description }}
            </span>
            <img :src="place.image" alt="" />
            <!-- <span v-if="place.image" class="distance">
                  {{ place.image }}
                </span> -->
            <a
              :href="place.url"
              target="_blank"
              :title="place.url"
              class="distance"
            >
              {{ place.url }}
            </a>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
export default {
  name: "NearByBoxInfoMolecule",
  props: {
    info: {
      Type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    navigationTab: null,
  }),
};
</script>

<style></style>

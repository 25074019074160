<template>
  <div>
    <h2 v-if="info.stadium_name" class="stadium-name">
      {{ info.stadium_name | capitalize }}
    </h2>
    <div class="d-flex locatedat">
      <img class="maplogo mr-3" src="@/assets/images/maplogo.png" alt="" />
      <span v-if="info.stadium_name">
        {{ info.stadium_name | capitalize }}&nbsp;
      </span>
      <span v-if="info.stadium_city">
        {{ info.stadium_city | capitalize }}
      </span>
      <span v-if="info.stadium_country">
        {{ info.stadium_country | capitalize }}
      </span>
    </div>
    <div v-if="info.stadium_capacity" class="d-flex capacity">
      <img class="courticon mr-2" src="@/assets/images/court.png" alt="" />
      <span>
        Stadium Capacity:
        <span class="font-weight-bold">
          {{ info.stadium_capacity }}
        </span>
      </span>
    </div>
    <div class="btn-group">
      <v-btn :ripple="false" outlined class="btn-getting mr-2">
        Getting there
        <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg"
      /></v-btn>
      <v-btn
        :ripple="false"
        :href="info.map_url"
        target="_blank"
        outlined
        class="btn-viewonmap"
      >
        View on map
        <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg" />
      </v-btn>
    </div>
    <div v-if="info.tournament_description" class="aboutvenue">
      <div v-html="info.tournament_description"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VenueCardMolecules",
  props: {
    info: {
      Type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style></style>

<template>
  <div class="ticket-banner">
    <template v-if="fixtureDetails">
      <div class="d-flex banner-topnav">
        <div class="event-date d-flex align-center">
          <div class="date">
            {{ fixtureDetails.fixture_date | moment("DD") }}
          </div>
          <div class="month">
            {{ fixtureDetails.fixture_date | moment("MMMM YYYY") }}
          </div>
        </div>
        <div class="map-location">
          <v-icon color="#070B32" size="18" class="pr-1">mdi-map-marker</v-icon>
          <span v-if="fixtureDetails.stadium_city">
            {{ fixtureDetails.stadium_city | capitalize }}
          </span>
          <span v-if="fixtureDetails.stadium_capacity">
            &nbsp; | Capacity: {{ fixtureDetails.stadium_capacity }}
          </span>
          <span> – <a class="link">See what’s nearby</a></span>
        </div>
      </div>
      <div class="banner-cards">
        <v-card class="team-logo" elevation="0">
          <div class="logos">
            <v-avatar size="48">
              <img
                class="logo1"
                :src="fixtureDetails.team1_logo"
                :alt="fixtureDetails.team1_name"
              />
            </v-avatar>
            <span class="match-between">vs</span>
            <v-avatar size="48">
              <img
                class="logo2"
                :src="fixtureDetails.team2_logo"
                :alt="fixtureDetails.team2_name"
              />
            </v-avatar>
          </div>
          <div class="name">
            <v-avatar size="20">
              <img
                class="icon"
                :src="fixtureDetails.tournament_image"
                :alt="fixtureDetails.tournament_name"
              />
            </v-avatar>
            <span class="ml-2">
              {{ fixtureDetails.tournament_name }}
            </span>
          </div>
        </v-card>
        <v-card class="team-info" elevation="0">
          <div class="team-names">
            <span> {{ fixtureDetails.team1_name }} </span>
            <span class="font-weight-regular ml-2 mr-2 versus"> vs </span>
            <span> {{ fixtureDetails.team2_name }} </span>
          </div>
          <div class="address-info address-info-new d-flex align-center">
            <div class="d-flex location">
              <img class="courticon" src="@/assets/images/court.svg" alt="" />
              <span style="font-weight: bold" class="pl-1">
                {{ convertHours(fixtureDetails.start_time, "12") }}
              </span>
              <span class="ml-1 mr-1">|</span>
              <span v-if="fixtureDetails.stadium_name">
                {{ fixtureDetails.stadium_name }},
              </span>
              <span class="ml-1 mr-1" v-if="fixtureDetails.stadium_city">
                {{ fixtureDetails.stadium_city }}
              </span>
              <span class="ml-1 mr-1">-</span>
              <a
                v-if="fixtureDetails.map_url"
                :href="fixtureDetails.map_url"
                target="_blank"
                class="link"
              >
                View on maps
              </a>
            </div>
          </div>
        </v-card>
      </div>
    </template>
    <template v-else>
      <v-skeleton-loader
        v-bind="{
          class: 'mb-6',
          boilerplate: false,
          elevation: 0,
        }"
        type="image"
      ></v-skeleton-loader>
    </template>
  </div>
</template>
<script>
import TwentyFourTwelveHours from "@/mixins/Dates/TwentyFourTwelveHours";
export default {
  name: "TicketsBannerCard",
  mixins: [TwentyFourTwelveHours],
  computed: {
    fixtureDetails() {
      return this.$store.state.fixtures.fixtureDetails;
    },
  },
};
</script>
<style></style>
